/* 在小屏幕设备上使用的样式 */
@media screen and (max-width: 768px) {
    .section{
        .culture-content {
            position: absolute;
            transform: 'translateX(-50%)';
            bottom: 0;
            z-index: 9999;
            width: 272px;
            text-align: left;
            color: #FFF;
            padding-left: 24px;
            padding-bottom: 56px;
            .section-item-title{
                font-size: 28px;
                font-weight: 600;
                line-height: 36px;
                margin-bottom: 24px;
            }
            .section-item-desc1 {
                margin-top: 12px;
                font-weight: 300;
                line-height: 22px;
                font-size: 14px;
            }
        }
    }
 
}
   
    /* 在中等屏幕设备上使用的样式 */
  @media screen and (min-width: 769px) and (max-width: 1024px) {

    .section{
        .culture-content {
            position: absolute;
            top: 50%;
            // transform: translate(-50%, -50%);
            z-index: 9999;
            width: 508px;
            text-align: left;
            color: #FFF;
            padding-left: 48px;
            .section-item-title{
                font-size: 52px;
                font-weight: 600;
                line-height: 56px;
                margin-bottom: 24px;
            }
            .section-item-desc1 {
                font-weight: 400;
                line-height: 22px;
                font-size: 18px;
            }
        }
    }
  }

   /* 在大屏幕设备上使用的样式 */ 
  @media screen and (min-width: 1025px) {
    .culture {
        .section{
            .culture-content {
                position: absolute;
                top: 50%;
                left: 120px;
                // transform: translate(-50%, -50%);
                z-index: 9999;
                width: 508px;
                text-align: left;
                color: #FFF;
                .section-item-title{
                    font-size: 52px;
                    font-weight: 600;
                    line-height: 68px;
                    margin-bottom: 24px;
                }
                .section-item-desc1 {
                    font-weight: 400;
                    line-height: 26px;
                    font-size: 18px;
                }
            }
        }
    }

  }