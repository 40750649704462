.sectionCompany{
  
  .fp.fp-overflow{
    height: calc(var(--vh) * 100);
    width: 100vw;
  }
}
/* 在小屏幕设备上使用的样式 */
@media screen and (max-width: 768px) {
    .section{
        .content {
            position: absolute;
            transform: 'translateX(-50%)';
            left: 24px;
            bottom: 56px;
            z-index: 9999;
            width: 272px;
            text-align: left;
            color: #FFF;
            .section-item-title{
                font-size: 28px;
                font-weight: 600;
                line-height: 36px;
            }
            .section-item-desc {
                margin-top: 12px;
                font-weight: 300;
                line-height: 22px;
            }
            .section-item-btn{
              margin-top: 32px;
              cursor: pointer;
              // width: 157px;
              padding: 0 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              border: 2px solid #FF5010;
              color: var(--Neutral-Absolute-1_white, #FFF);
              font-size: 16px;
              font-style: normal;
              font-family: Avenir;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
        }
    }
 
}
   
    /* 在中等屏幕设备上使用的样式 */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .section{
        .content {
            position: absolute;
            top: 50%;
            left: 15%;
            // transform: translate(-50%, -50%);
            z-index: 9999;
            width: 508px;
            text-align: left;
            color: #FFF;
            .section-item-title{
                font-size: 52px;
                font-weight: 600;
                line-height: 36px;
            }
            .section-item-desc {
                margin-top: 24px;
                font-weight: 400;
                line-height: 22px;
                font-size: 18px;
            }
            .section-item-btn{
              margin-top: 32px;
              cursor: pointer;
              // width: 167px;
              padding: 0 40px;
              height: 52px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              border: 2px solid #FF5010;
              color: var(--Neutral-Absolute-1_white, #FFF);
              font-family: Avenir;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
        }
    }
  }

   /* 在大屏幕设备上使用的样式 */ 
  @media screen and (min-width: 1025px) {
    .section{
        .content {
            position: absolute;
            top: 50%;
            left: 10%;
            // transform: translate(-50%, -50%);
            z-index: 9999;
            width: 508px;
            text-align: left;
            color: #FFF;
            .section-item-title{
                font-size: 52px;
                font-weight: 600;
                line-height: 36px;
            }
            .section-item-desc {
                margin-top: 24px;
                font-weight: 400;
                line-height: 22px;
                font-size: 18px;
            }
            .section-item-btn{
              margin-top: 45px;
              cursor: pointer;
              // width: 167px;
              padding: 0 40px;
              height: 52px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              border: 2px solid #FF5010;
              color: var(--Neutral-Absolute-1_white, #FFF);
              font-family: Avenir;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            .section-item-btn:hover{
              background-color: #FF5010;
              color: #fff;
            }
        }
    }
  }