.scroll{
  width: 6px;
  height: calc(var(--vh) * 100);
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;

  div{
    flex-grow: 1;
  }

  .targetScroll{
    background: var(--Primary-Default, #FF5010);
    transition: 300ms;
    transition-timing-function: ease-in-out;
  }
}