

.section{
    .section-content{
         position: absolute;
         bottom: 10%;
         padding: 24px;
         color: #fff;
         width: 278px;
         padding-right: 0;
         .section-item-flag{
             font-size: 28px;
             font-weight: 600;
             line-height: 36px;
             margin-bottom: 12px;
         }
         .section-item-flag{
             margin-bottom: 24px;
         }
         .section-item-flagDesc{
             margin-bottom: 36px;
             font-size: 14px;
         }
         .section-item-btn {
             padding: 8px 40px;
             height: 36px;
             border-radius: 4px;
             border: 2px solid #FF5010;
             color: #fff;
             &:hover, &:active {
                 cursor: pointer;
                 border: 2px solid #FF5010;
                 background: #FF5010;
             }
         }
    }
 }