



  @media screen and (max-width: 768px) {
    /* 在小屏幕设备上使用的样式 */
    .scrollbar{
        width: 6px;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
    
        div{
        flex-grow: 1;
        }
    
        .targetScroll{
          background: var(--Primary-Default, #FF5010);
          transition: 300ms;
          transition-timing-function: ease-in-out;
        }
    }
  }
   
  @media screen and (min-width: 769px) and (max-width: 1024px) {
        /* 在中等屏幕设备上使用的样式 */
        .scrollbar{
            width: 6px;
            height: 100vh;
            position: fixed;
            top: 0;
            right: 0;
            display: flex;
            flex-direction: column;
          
            div{
              flex-grow: 1;
            }
          
            .targetScroll{
              background: var(--Primary-Default, #FF5010);
              transition: 300ms;
              transition-timing-function: ease-in-out;
            }
        }
  }
   
  @media screen and (min-width: 1025px) {
        /* 在大屏幕设备上使用的样式 */
        .scrollbar{
            width: 6px;
            height: 100vh;
            position: fixed;
            top: 0;
            right: 0;
            display: flex;
            flex-direction: column;
          
            div{
              flex-grow: 1;
            }
          
            .targetScroll{
              background: var(--Primary-Default, #FF5010);
              transition: 300ms;
              transition-timing-function: ease-in-out;
            }
          }
  }