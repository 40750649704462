/* 在小屏幕设备上使用的样式 */
@media screen and (max-width: 768px) {
    .city-info{
        top: 0;
        overflow: hidden !important;
        margin: 0 !important;
        max-width: 100vw !important;
        .ant-modal-content{
            border-radius: 0;
            .ant-modal-close-x{
                color: #000;
            }
        }
        
    }
    .content{
        color: #000;
        .content-city-name {
            font-size: 28px;
            font-weight: 400;
            line-height: 64px;
            margin: 24px 0;
        }
        .content-city-desc{
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            &:last-of-type {
                margin-bottom: 24px;
            }
        }
        .content-city-descImg{
            width: 327px;
            height: 245px;
            margin-bottom: 24px;
        }
    }
 
}
   
    /* 在中等屏幕设备上使用的样式 */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .city-info{
        overscroll-behavior: auto none;
        .ant-modal-content{
            height: 682px;
            overflow: scroll;
            border-radius: 0;
            overscroll-behavior: auto none;
            .ant-modal-close-x{
                color: #000;
            }
        }
    }
    .content{
        color: #000;
        .content-city-name {
            font-size: 52px;
            font-weight: 400;
            line-height: 64px;
            margin: 24px 0;
        }
        .content-city-desc{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            &:last-of-type {
                margin-bottom: 24px;
            }
        }
        .content-city-descImg{
            width: 100%;
            height: 100%;
            margin-bottom: 24px;
        }
    }
  }

   /* 在大屏幕设备上使用的样式 */ 
  @media screen and (min-width: 1025px) {
    .city-info{
        overscroll-behavior: auto none;
        .ant-modal-content{
            height: 682px;
            overflow: scroll;
            border-radius: 0;
            overscroll-behavior: auto none;
            .ant-modal-close-x{
                color: #000;
            }
        }

    }
    .content{
        color: #000;
        .content-city-name {
            font-size: 52px;
            font-weight: 400;
            line-height: 64px;
            margin: 24px 0;
        }
        .content-city-desc{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            &:last-of-type {
                margin-bottom: 24px;
            }
        }
        .content-city-descImg{
            width: 100%;
            height: 100%;
            margin-bottom: 24px;
        }
    }
  }