.HeadMenu{
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 48px;
  z-index: 97;
  .HeadPanelLogo{
    cursor: pointer;
  }
  .HeadMenuBtn{
    position: relative;
    z-index: 99;
    svg{
      path{
        transition: 300ms;
      }
    }
  }
  .HeadMenuBtn:hover{
    cursor: pointer;
  }
  .HeadMenuMask{
    height: calc(var(--vh) * 100);
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 48px;
    transition: 300ms;
    background-color: #fff;

    .HeadMenuPanel{
      transition: 300ms;
    }
  }


}
@media screen and (min-width: 1025px) {
  .HeadMenu {
    height: 120px;
  }

  .HeadMenuLogo{
    // position: relative;
    // top: 48px;
    // transform: translateY(50%);
  }

  .HeadMenuBtn{
    // position: relative;
    // top: 48px;
    // transform: translateY(50%);
  }
  
  .HeadMenuBtn:hover{
    cursor: pointer;
    svg{
      // filter: drop-shadow(#FF5010 8000px 0px 0px);
      // transform: translateX(-8000px);
      // fill: #FF5010 
      path{
        fill: #FF5010 
      }
    }
  }

  .HeadPanelLogo{
    position: absolute;
    top: 48px;
    left: 48px;
  }

  .HeadMenuPanel{
    width: 320px;
    height: calc(var(--vh) * 100);
    position: absolute;
    right: 0;
    transform: translateX(320px);
    background-color: #fff;
    box-sizing: border-box;
    padding: 48px;
  }

  .HeadPanelBox{
    box-sizing: border-box;
    padding-top: 78px;
    display: flex;
    flex-direction: column;

    div{
      cursor: pointer;
    }

    .HeadPanelModules{
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      margin-top: 24px;
      &:hover, &:active {
        color: #ff5010;
      }
    }

    .HeadPanelLink{
      a{
        color: var(--Neutral-Absolute-8, #595959);
      }
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-top: 16px;
    }

    .HeadPanelLinkFirst{
      margin-top: 48px;
    }
  }
  .HeadPanelCountry{
    position: absolute;
    bottom: 56px;
    left: 48px;
    display: flex;

    .HeadPanelCountryItem{
      cursor: pointer;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 48px;
      color: var(--Neutral-Absolute-13_black, #595959);
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .onSelectItem{
      cursor: pointer;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 48px;
      // color: var(--Neutral-Absolute-13_black, #595959);
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      color: var(--Neutral-Absolute-13_black, #000);
      border-bottom: #FF5010 1px solid;
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px)  {
  .HeadMenu {
    height: 120px;
  }

  .HeadPanelLogo{
    position: absolute;
    top: 48px;
    left: 48px;
  }

  .HeadMenuPanel{
    width: 320px;
    height: calc(var(--vh) * 100);
    position: absolute;
    right: 0;
    transform: translateX(320px);
    background-color: #fff;
    box-sizing: border-box;
    padding: 48px;
  }

  .HeadPanelBox{
    box-sizing: border-box;
    padding-top: 78px;
    display: flex;
    flex-direction: column;

    div{
      cursor: pointer;
    }

    .HeadPanelModules{
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      margin-top: 24px;
    }

    .HeadPanelLink{
      a{
        color: var(--Neutral-Absolute-8, #595959);
      }
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-top: 16px;
    }

    .HeadPanelLinkFirst{
      margin-top: 48px;
    }
  }
  .HeadPanelCountry{
    position: absolute;
    bottom: 56px;
    left: 48px;
    display: flex;

    .HeadPanelCountryItem{
      cursor: pointer;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 48px;
      color: var(--Neutral-Absolute-13_black, #595959);
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .onSelectItem{
      cursor: pointer;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 48px;
      color: var(--Neutral-Absolute-13_black, #595959);
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      color: var(--Neutral-Absolute-13_black, #000);
      border-bottom: #FF5010 1px solid;
    }
  }
}
@media screen and (max-width: 768px) {
  .HeadMenu {
    height: 88px;
    padding: 0 24px;
  }

  .HeadMenuLogo{
    height: 24px;
    width: 157px;
    svg{
      width: 157px;
      height: 16px;
    }
  }

  .HeadPanelLogo{
    position: absolute;
    top: 32px;
    left: 24px;
  }

  .HeadMenuPanel{
    width: 100vw;
    height: calc(var(--vh) * 100);
    position: absolute;
    right: 0;
    transform: translateX(320px);
    background-color: #fff;
    box-sizing: border-box;
    padding: 32px 24px;
  }

  .HeadPanelBox{
    box-sizing: border-box;
    padding-top: 60px;
    display: flex;
    flex-direction: column;

    div{
      cursor: pointer;
    }

    .HeadPanelModules{
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      margin-top: 16px;
    }

    .HeadPanelLink{
      a{
        color: var(--Neutral-Absolute-8, #999);
      }
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-top: 16px;
    }

    .HeadPanelLinkFirst{
      margin-top: 32px;
    }
  }
  .HeadPanelCountry{
    position: absolute;
    bottom: 100px;
    left: 24px;
    display: flex;

    .HeadPanelCountryItem{
      cursor: pointer;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 48px;
      color: var(--Neutral-Absolute-13_black, #595959);
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .onSelectItem{
      cursor: pointer;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 48px;
      color: var(--Neutral-Absolute-13_black, #595959);
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      color: var(--Neutral-Absolute-13_black, #000);
      border-bottom: #FF5010 1px solid;
    }
  }
}