/* 在小屏幕设备上使用的样式 */
@media screen and (max-width: 768px) {
    .section{
      height: calc(var(--vh) * 100);
      .fp-overflow{
        height: calc(var(--vh) * 100);
        width: 100vw;
      }
       .section-joinus-content{
            position: absolute;
            bottom: 10%;
            padding: 24px;
            color: #fff;
            .section-item-title, 
            .section-item-flag{
                font-size: 28px;
                font-weight: 600;
                line-height: 36px;
                margin-bottom: 12px;
            }
            .section-item-flag{
                margin-bottom: 24px;
            }
            .section-joinus-content-desc{
                width: 272px;
                line-height: 24px;
                margin-bottom: 36px;
                font-weight: 300;
                font-size: 14px;
                &:last-of-type{
                    display: none;
                }
            }
            .desc-love,
            .desc-openness{
                width: 262px;
            }
            .desc-health{
                width: 240px;
            }
            .section-item-btn {
                padding: 8px 40px;
                height: 36px;
                border-radius: 4px;
                border: 2px solid #FF5010;
                color: #fff;
                &:hover, &:active {
                    cursor: pointer;
                    border: 2px solid #FF5010;
                    background: #FF5010;
                }
            }
       }
    }
 
}
   
    /* 在中等屏幕设备上使用的样式 */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .section{
      height: calc(var(--vh) * 100);
      .fp-overflow{
        height: calc(var(--vh) * 100);
        width: 100vw;
      }
        .section-left{
            color:#fff;
            width: 50vw;
            height: calc(var(--vh) * 100);
            display: flex;
            justify-content: center;
            align-items: center;
            .section-left-img{
                width: 100vw;
                height: calc(var(--vh) * 100);
            }
            .section-joinus-content{
                width: 50vw;
                padding: 0 50px;
                box-sizing: border-box;
                position: absolute;
                .section-item-flag {
                    font-size: 52px;
                    font-weight: 600;
                    line-height: 60px;
                    margin-bottom: 24px;
                }
                .section-item-flagDesc {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-top: 24px;
                    margin-bottom: 32px;
                }
                .section-item-btn {
                    color: #fff;
                    padding: 13px 40px;
                    height: 48px;
                    border-radius: 4px;
                    border: 2px solid #FF5010;
                    &:hover, &:active {
                        cursor: pointer;
                        border-radius: 4px;
                        border: 2px solid #FF5010;
                        background: #FF5010;
                    }
                }
            }
        }
        .section-right{
            background: #fff;
            width: 50vw;
            height: calc(var(--vh) * 100);
            display: flex;
            justify-content: center;
            align-items: center;
            .section-joinus-content{
                width: 50vw;
                padding: 0 50px;
                box-sizing: border-box;
                .section-joinus-content-title {
                    color: #000;
                    font-size: 52px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 60px;
                    margin-bottom: 24px;
                } 
                .section-joinus-content-desc{
                    color: #8C8C8C;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px; 
                    margin-bottom: 32px;
                }
            }
        }
    }
  }

   /* 在大屏幕设备上使用的样式 */ 
  @media screen and (min-width: 1025px) {
    .section{
      height: calc(var(--vh) * 100);
      .fp-overflow{
        height: calc(var(--vh) * 100);
        width: 100vw;
      }
        .section-left{
            color:#fff;
            width: 50vw;
            height: calc(var(--vh) * 100);
            display: flex;
            justify-content: center;
            align-items: center;
            .section-left-img{
                width: 100vw;
                height: calc(var(--vh) * 100);
            }
            .section-joinus-content{
                width: 480px;
                position: absolute;
                .section-item-flag {
                    font-size: 52px;
                    font-weight: 600;
                    line-height: 60px;
                    margin-bottom: 24px;
                }
                .section-item-flagDesc {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-top: 24px;
                    margin-bottom: 32px;
                }
                .section-item-btn {
                    color: #fff;
                    padding: 13px 40px;
                    height: 48px;
                    border-radius: 4px;
                    border: 2px solid #FF5010;
                    font-size: 18px;
                    &:hover, &:active {
                        cursor: pointer;
                        border-radius: 4px;
                        border: 2px solid #FF5010;
                        background: #FF5010;
                    }
                }
            }
        }
        .section-right{
            background: #fff;
            width: 50vw;
            height: calc(var(--vh) * 100);
            display: flex;
            justify-content: center;
            align-items: center;
            .section-joinus-content{
                width: 508px;
                margin-top: -32px;
                .section-joinus-content-title {
                    color: #000;
                    font-size: 52px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 60px;
                    margin-bottom: 24px;
                } 
                .section-joinus-content-desc{
                    color: #8C8C8C;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px; 
                    margin-bottom: 32px;
                }
            }
        }
    }
  }

  a {
    text-decoration: none;
  }