.fp-overflow{
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}
// .callUsSection{
//   padding-bottom: 56px;
//   @media screen and (min-width: 1025px) {
//     .callUsItemPad{
//       display: none;
//     }
//     .fp-overflow{
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       box-sizing: border-box;
//     }

//     .headMenuBg{
//       width: 100%;
//       height: 120px;
//     }

//     .callUsTitle{
//       color: #000;
//       font-size: 52px;
//       font-style: normal;
//       font-weight: 600;
//       line-height: 60px; /* 115.385% */
//       margin-top: 40px;
//     }

//     .callUsBox{
//       width: 100%;
//       display: flex;
//       justify-content: center;
//       margin-top: 120px;
//       box-sizing: border-box;
//       justify-content: center;

//       .callUsItem{
//         width: 376px;
//         display: flex;
//         flex-direction: column;
//         margin: 0 32px;

//         .callUsItemWay{
//           color: #000;
//           font-family: Avenir;
//           font-size: 18px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: 26px; /* 144.444% */
//           display: flex;
//           align-items: center;

//           svg{
//             width: 32px;
//             height: 32px;
//             margin-right: 8px;
//           }
//         }

//         .callUsItemNum{
//           color: #000;
//           font-family: Avenir;
//           font-size: 32px;
//           font-style: normal;
//           font-weight: 900;
//           line-height: 40px; /* 125% */
//           margin-top: 16px;
//         }

//         .callUsItemTime{
//           display: flex;
//           flex-direction: column;
//           color: var(--Neutral-Absolute-8, #595959);
//           font-size: 14px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: 22px; /* 157.143% */
//           margin-top: 24px;
//           height: 44px;
//         }

//         .callUsItemBtn{
//           display: flex;
//           width: 152px;
//           height: 52px;
//           justify-content: center;
//           align-items: center;
//           border-radius: 4px;
//           border: 2px solid #FF5010;
//           color: var(--Neutral-Absolute-12, #141414);
//           font-size: 18px;
//           font-style: normal;
//           font-weight: 500;
//           line-height: 26px; /* 144.444% */
//           margin-top: 24px;
//           cursor: pointer;
//         }

//         .callUsItemBtn:hover{
//           color: #FFF;
//           background-color: #FF5010;
//         }
//       }
          
//       .callUsItemPad{
//         display: none;
//       }

//     }
//   }

//   @media screen and (min-width: 769px) and (max-width: 1024px) {
//     .callUsItemPad{
//       display: block;
//     }
//     .fp-overflow{
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       box-sizing: border-box;
//     }

//     .headMenuBg{
//       width: 100%;
//       height: 88px;
//     }

//     .callUsTitle{
//       color: #000;
//       font-size: 48px;
//       font-style: normal;
//       font-weight: 600;
//       line-height: 60px; /* 115.385% */
//       margin-top: 48px;
//     }

//     .callUsBox{
//       width: 100%;
//       display: flex;
//       justify-content: center;
//       flex-wrap: wrap;
//       margin-top: 120px;
//       box-sizing: border-box;

//       .callUsItem{
//         width: 300px;
//         display: flex;
//         flex-direction: column;
//         margin: 32px;

//         .callUsItemWay{
//           color: #000;
//           font-family: Avenir;
//           font-size: 18px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: 26px; /* 144.444% */
//           display: flex;
//           align-items: center;

//           svg{
//             width: 32px;
//             height: 32px;
//             margin-right: 8px;
//           }
//         }

//         .callUsItemNum{
//           color: #000;
//           font-family: Avenir;
//           font-size: 32px;
//           font-style: normal;
//           font-weight: 900;
//           line-height: 40px; /* 125% */
//           margin-top: 16px;
//         }

//         .callUsItemTime{
//           display: flex;
//           flex-direction: column;
//           color: var(--Neutral-Absolute-8, #595959);
//           font-size: 14px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: 22px; /* 157.143% */
//           margin-top: 24px;
//           height: 44px;
//         }

//         .callUsItemBtn{
//           display: flex;
//           width: 152px;
//           height: 52px;
//           justify-content: center;
//           align-items: center;
//           border-radius: 4px;
//           border: 2px solid #FF5010;
//           color: var(--Neutral-Absolute-12, #141414);
//           font-size: 18px;
//           font-style: normal;
//           font-weight: 500;
//           line-height: 26px; /* 144.444% */
//           margin-top: 24px;
//           cursor: pointer;
//         }

//         .callUsItemBtn:hover{
//           color: #FFF;
//           background-color: #FF5010;
//         }
//       }
//     }
//   }
  
//   @media screen and (max-width: 768px) {
//     .fp-overflow{
//       display: flex;
//       // padding: 0 24px 0 24px;
//       box-sizing: border-box;
//       flex-direction: column;
//     }

//     .headMenuBg{
//       width: 100%;
//       height: 88px;
//     }

//     .callUsTitle{
//       color: var(--Neutral-Absolute-13_black, #000);
//       font-size: 28px;
//       font-style: normal;
//       font-weight: 600;
//       line-height: 36px; /* 128.571% */
//       margin-top: 26px;
//     }

//     .callUsBox{
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       margin-top: 48px;
//       box-sizing: border-box;

//       .callUsItem{
//         width: 100%;
//         display: flex;
//         flex-direction: column;
//         margin-bottom: 36px;

//         .callUsItemWay{
//           color: #000;
//           font-family: Avenir;
//           font-size: 18px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: 26px; /* 144.444% */
//           display: flex;
//           align-items: center;

//           svg{
//             width: 32px;
//             height: 32px;
//             margin-right: 8px;
//           }
//         }

//         .callUsItemNum{
//           color: #000;
//           font-family: Avenir;
//           font-size: 32px;
//           font-style: normal;
//           font-weight: 900;
//           line-height: 40px; /* 125% */
//           margin-top: 16px;
//         }

//         .callUsItemTime{
//           display: flex;
//           flex-direction: column;
//           color: var(--Neutral-Absolute-8, #595959);
           
//           font-size: 14px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: 22px; /* 157.143% */
//           margin-top: 12px;
//           height: 44px;
//         }

//         .callUsItemTimeEmail{
//           display: none;
//         }

//         .callUsItemBtn{
//           display: flex;
//           width: 144px;
//           height: 40px;
//           justify-content: center;
//           align-items: center;
//           border-radius: 4px;
//           border: 2px solid #FF5010;
//           color: var(--Neutral-Absolute-12, #141414);
           
//           font-size: 16px;
//           font-style: normal;
//           font-weight: 500;
//           line-height: 24px; /* 144.444% */
//           margin-top: 12px;
//           cursor: pointer;
//         }

//         .callUsItemBtn:hover{
//           color: #FFF;
//           background-color: #FF5010;
//         }
//       }
//     }
//   }
// }