.fp-overflow {
  height: calc(var(--vh) * 100);
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  font-family: avenir;
}

// .sectionProduct{
@media screen and (min-width: 1025px) {
  .sectionProductDialog {
    padding-bottom: 0;
    width: 900px !important;
    height: calc(var(--vh) * 100 - 128px);
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    top: 64px;
    // padding: 0 24px;
    .ant-modal-content {
      height: 100%;
    }

    .close-H5 {
      display: none;
    }

    .sectionProductDialog-close {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }

    .sectionProductDialog-title {
      margin-top: 80px;
      color: var(--Neutral-Absolute-13_black, #000);

      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 68px; /* 113.333% */
      font-family: Avenir;
    }

    .sectionProductDialog-data {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 32px;

      .sectionProductDialog-dateItem {
        display: flex;
        flex-direction: column;
        margin-right: 48px;

        div:first-child {
          color: var(--Neutral-Absolute-8, #595959);
          text-align: justify;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 171.429% */
          white-space: nowrap;
        }

        div:last-child {
          color: var(--Neutral-Absolute-13_black, #000);
          text-align: justify;

          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px; /* 125% */
        }
      }

      .dateItem:first-child {
        margin-right: 48px;
      }
    }

    .sectionProductDialog-info {
      margin-top: 32px;
      color: var(--Neutral-Absolute-13_black, #000);
      text-align: justify;

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 144.444% */
      text-align: left;
      white-space: break-spaces;
    }

    .sectionProductDialog-buttonBox {
      display: flex;
      margin-top: 32px;
    }

    .sectionProductDialog-sectionButton {
      display: flex;
      padding: 13px 40px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 2px solid #ff5010;
      color: var(--Neutral-Absolute-13_black, #000);

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
      white-space: nowrap;
      cursor: pointer;
    }

    .sectionProductDialog-sectionButton:hover {
      background-color: #ff5010;
      color: #fff;
    }
  }
  .sectionProductDialog > div {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .sectionProductDialog {
    top: 0;
    padding-bottom: 0;
    width: 100vw !important;
    height: calc(var(--vh) * 100);
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    // padding: 0 24px 0 24px;
    position: relative;
    margin: 0 !important;
    max-width: unset !important;
    .ant-modal-content {
      height: 100%;
    }
    .sectionProductDialog-content {
      height: calc(100vh - 80px);
      position: relative;
      //   bottom: -80px;
      overflow-y: scroll;
      padding-bottom: 100px;
    }

    .close-PC {
      display: none;
    }

    .sectionProductDialog-close {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 80px;
      right: 0px;
      cursor: pointer;
    }

    .sectionProductDialog-title {
      margin-top: 80px;
      color: var(--Neutral-Absolute-13_black, #000);

      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 113.333% */
    }

    .sectionProductDialog-data {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 24px;

      .sectionProductDialog-dateItem {
        display: flex;
        flex-direction: column;
        margin-right: 32px;

        div:first-child {
          color: var(--Neutral-Absolute-8, #595959);
          text-align: justify;

          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 171.429% */
          white-space: nowrap;
        }

        div:last-child {
          color: var(--Neutral-Absolute-13_black, #000);
          text-align: justify;

          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px; /* 125% */
        }
      }

      .dateItem:first-child {
        margin-right: 32px;
      }
    }

    .sectionProductDialog-info {
      margin-top: 32px;
      color: var(--Neutral-Absolute-13_black, #000);
      text-align: justify;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 144.444% */
      text-align: left;
      white-space: break-spaces;
    }

    .sectionProductDialog-buttonBox {
      display: flex;
      margin-top: 32px;
    }

    .sectionProductDialog-sectionButton {
      display: flex;
      padding: 8px 40px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 2px solid #ff5010;
      color: var(--Neutral-Absolute-13_black, #000);

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 144.444% */
      white-space: nowrap;
      cursor: pointer;
    }

    // .sectionProductDialog-sectionButton:hover{
    //   background-color: #FF5010;
    //   color: #fff
    // }
  }

  .sectionProductDialog > div {
    height: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .sectionProductDialog {
    width: 100vw !important;
    height: calc(var(--vh) * 100);
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 0;
    top: 0;
    margin: 0 !important;
    max-width: unset !important;
    // padding: 88px 24px 0 24px;
    .ant-modal-content {
      height: 100%;
    }
    .close-H5 {
      display: none;
    }

    .sectionProductDialog-close {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 120px;
      right: 24px;
      cursor: pointer;
    }

    .sectionProductDialog-title {
      margin-top: 80px;
      color: var(--Neutral-Absolute-13_black, #000);

      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 113.333% */
    }

    .sectionProductDialog-data {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 24px;

      .sectionProductDialog-dateItem {
        display: flex;
        flex-direction: column;
        margin-right: 32px;

        div:first-child {
          color: var(--Neutral-Absolute-8, #595959);
          text-align: justify;

          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 171.429% */
          white-space: nowrap;
        }

        div:last-child {
          color: var(--Neutral-Absolute-13_black, #000);
          text-align: justify;

          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px; /* 125% */
        }
      }

      .dateItem:first-child {
        margin-right: 32px;
      }
    }

    .sectionProductDialog-info {
      margin-top: 32px;
      color: var(--Neutral-Absolute-13_black, #000);
      text-align: justify;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 144.444% */
      text-align: left;
      white-space: break-spaces;
    }

    .sectionProductDialog-buttonBox {
      display: flex;
      margin-top: 32px;
    }

    .sectionProductDialog-sectionButton {
      display: flex;
      padding: 8px 40px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 2px solid #ff5010;
      color: var(--Neutral-Absolute-13_black, #000);

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 144.444% */
      white-space: nowrap;
      cursor: pointer;
    }

    // .sectionProductDialog-sectionButton:hover{
    //   background-color: #FF5010;
    //   color: #fff
    // }
  }

  .sectionProductDialog > div {
    height: 100%;
  }
}
// }

@media screen and (min-width: 1025px) {
  .fp-overflow {
    display: flex;
    flex-wrap: nowrap;
  }
  .sectionBg {
    height: calc(var(--vh) * 100);
    flex-shrink: 0;
  }

  .sectionContentBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .sectionContent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // flex-grow: 1;

    .title {
      width: 522px;
      color: var(--Neutral-Absolute-12, #141414);
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 68px;
    }

    .data {
      width: 508px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 32px 0 32px 0;

      .dateItem {
        display: flex;
        flex-direction: column;

        div:first-child {
          color: var(--Neutral-Absolute-8, #595959);
          text-align: justify;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 171.429% */
          white-space: nowrap;
        }

        div:last-child {
          color: var(--Neutral-Absolute-13_black, #000);
          text-align: justify;

          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px; /* 125% */
        }
      }

      .dateItem:first-child {
        margin-right: 48px;
      }
    }

    .info {
      width: 508px;
      height: 78px;
      color: var(--Neutral-Absolute-8, #595959);
      text-align: justify;

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 150% */
      margin: 24px 0 32px 0;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      span {
        span {
          cursor: pointer;
          color: #ff5010;
        }
      }
    }

    .buttonBox {
      display: flex;
      // width: 272px;
      text-align: left;
    }

    .button {
      padding: 0 40px;
      text-align: left;
      height: 52px;
      // min-width: 132px;
      padding: 0 40px;
      border-radius: 4px;
      border: 2px solid #ff5010;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Neutral-Absolute-12, #141414);

      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 22px;
      cursor: pointer;
    }
    
    .button:hover {
      transition: 100ms;
      background: #ff5010;
      color: #fff;
    }
  
  }

  .mark_h5 {
    display: none;
  }

  .mark_pad {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sectionBg {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .sectionContentBox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    height: calc(var(--vh) * 100);
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .sectionContent {
    box-sizing: border-box;
    padding-left: 24px;
    padding-bottom: 56px;
    color: #ffffff;

    .title {
      color: #fff;

      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      width: 260px;
    }

    .data {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 24px 0 16px 0;

      .dateItem {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        div:first-child {
          color: var(
            --neutral-opacity-white-ffffff-45,
            rgba(255, 255, 255, 0.45)
          );
          text-align: justify;

          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 166.667% */
          white-space: nowrap;
        }

        div:last-child {
          color: var(--Neutral-Absolute-1_white, #fff);
          text-align: justify;

          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px; /* 133.333% */
        }
      }

      .dateItem:first-child {
        margin-right: 32px;
      }
    }

    .info {
      width: 272px;
      height: 66px;
      color: #fff;
      text-align: justify;

      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      margin: 12px 0 32px 0;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      position: relative;

      span {
        span {
          cursor: pointer;
          color: #ff5010;
        }
      }
    }

    .buttonBox {
      display: flex;
      // width: 272px;
      text-align: left;
    }

    .button {
      padding: 0 40px;
      height: 36px;
      // min-width: 108px;
      border-radius: 4px;
      border: 2px solid #ff5010;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--Neutral-Absolute-1_white, #fff);

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
  }

  .mark_h5 {
    width: 100vw;
    height: calc(var(--vh) * 100);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 8;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .mark_pad {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .sectionBg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .sectionContent {
    height: calc(var(--vh) * 100);
    width: 100vw;
    box-sizing: border-box;
    padding-left: 24px;
    padding-bottom: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    .title {
      color: var(--Neutral-Absolute-1_white, #fff);

      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px; /* 116.667% */
    }

    .data {
      width: 508px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 24px 0 0 0;

      .dateItem {
        display: flex;
        flex-direction: column;

        div:first-child {
          color: var(
            --neutral-opacity-white-ffffff-45,
            rgba(255, 255, 255, 0.45)
          );
          text-align: justify;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 171.429% */
          white-space: nowrap;
        }

        div:last-child {
          color: var(--Neutral-Absolute-1_white, #fff);
          text-align: justify;

          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px; /* 125% */
        }
      }

      .dateItem:first-child {
        margin-right: 32px;
      }
    }

    .info {
      width: 508px;
      height: 72px;
      color: var(--Neutral-Absolute-1_white, #fff);
      text-align: justify;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin: 32px 0 32px 0;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      span {
        span {
          cursor: pointer;
          color: #ff5010;
        }
      }
    }

    .buttonBox {
      display: flex;
      // width: 272px;
      text-align: left;
    }

    .button {
      padding: 0 40px;
      height: 52px;
      // min-width: 132px;
      border-radius: 4px;
      border: 2px solid #ff5010;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      cursor: pointer;
    }
  }

  .mark_h5 {
    width: 100vw;
    height: calc(var(--vh) * 100);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 8;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.video-wrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  object-fit: cover;
  position: absolute;
  z-index: 1;
}

.sectionHome {
  display: flex;
  align-items: center;

  .fp-overflow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      svg {
        height: 12px;
        width: 118px;
        margin-top: calc(12px - 1rem);
        z-index: 99;
      }
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      svg {
        height: 20px;
        width: 196px;
        margin-top: calc(24px - 1rem);
        z-index: 99;
      }
    }

    @media screen and (min-width: 1025px) {
      svg {
        height: 20px;
        width: 196px;
        margin-top: calc(24px - 1rem);
        z-index: 99;
      }
    }
  }
}

.currentSubtitle {
  width: 100vw;
  text-align: center;
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%,-50%);
  z-index: 99;
}

.subtitle {
  opacity: 0.3;
  transition: opacity 0.5s ease-in;
}

.subtitle-fadein {
  opacity: 1;
}

.subtitle-fadeout {
  opacity: 0;
}

@keyframes fade {
  0% {
    color: rgba(255, 255, 255, 1);
  }

  30% {
    color: rgba(255, 255, 255, 0.8);
  }

  60% {
    color: rgba(255, 255, 255, 0.5);
  }

  80% {
    color: rgba(255, 255, 255, 0.8);
  }

  100% {
    color: rgba(255, 255, 255, 1);
  }
}

@media screen and (min-width: 1025px) {
  .currentSubtitle {
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 112.5% */
    color: #fff;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .currentSubtitle {
    color: #fff;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 116.667% */
  }
}

@media screen and (max-width: 768px) {
  .currentSubtitle {
    color: #fff;
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }
}

.callUsSection {
  .callUsTitle {
    text-align: center;
  }
  @media screen and (min-width: 1025px) {
    .callUsItemPad {
      display: none;
    }
    .fp-overflow {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
    }

    .headMenuBg {
      width: 100%;
      height: 120px;
      position: sticky;
      top: 0px;
      background-color: #fff;
    }

    .callUsTitle {
      color: #000;

      font-size: 52px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px; /* 115.385% */
      margin-top: 40px;
    }

    .callUsBox {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 120px;
      box-sizing: border-box;
      justify-content: center;

      .callUsItem {
        width: 376px;
        display: flex;
        flex-direction: column;
        margin: 0 32px;

        .callUsItemWay {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 144.444% */
          display: flex;
          align-items: center;

          svg {
            width: 32px;
            height: 32px;
            margin-right: 8px;
          }
        }

        .callUsItemNum {
          color: #000;
          font-size: 32px;
          font-style: normal;
          font-weight: 900;
          line-height: 40px; /* 125% */
          margin-top: 16px;
        }

        .callUsItemTime {
          display: flex;
          flex-direction: column;
          color: var(--Neutral-Absolute-8, #595959);

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          margin-top: 24px;
          height: 44px;

          .colon {
            display: none;
          }
        }

        .callUsItemBtn {
          display: flex;
          width: 152px;
          height: 52px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 2px solid #ff5010;
          color: var(--Neutral-Absolute-12, #141414);

          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 144.444% */
          margin-top: 24px;
          cursor: pointer;
          a {
            color: var(--Neutral-Absolute-12, #141414);
          }
        }

        .callUsItemBtn:hover {
          color: #fff;
          background-color: #ff5010;
          a {
            color: #fff;
          }
        }
      }

      .callUsItemPad {
        display: none;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .callUsItemPad {
      display: block;
    }
    .fp-overflow {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
    }

    .headMenuBg {
      width: 100%;
      height: 88px;
      position: sticky;
      top: 0px;
      background-color: #fff;
    }

    .callUsTitle {
      color: #000;

      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px; /* 115.385% */
      margin-top: 48px;
    }

    .callUsBox {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 120px;
      box-sizing: border-box;

      .callUsItem {
        width: 300px;
        display: flex;
        flex-direction: column;
        margin: 32px;

        .callUsItemWay {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 144.444% */
          display: flex;
          align-items: center;

          svg {
            width: 32px;
            height: 32px;
            margin-right: 8px;
          }
        }

        .callUsItemNum {
          color: #000;
          font-size: 32px;
          font-style: normal;
          font-weight: 900;
          line-height: 40px; /* 125% */
          margin-top: 16px;
        }

        .callUsItemTime {
          display: flex;
          flex-direction: column;
          color: var(--Neutral-Absolute-8, #595959);

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          margin-top: 24px;
          height: 44px;

          .colon {
            display: none;
          }
        }

        .callUsItemBtn {
          display: flex;
          width: 152px;
          height: 52px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 2px solid #ff5010;

          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 144.444% */
          margin-top: 24px;
          cursor: pointer;

          a {
            color: var(--Neutral-Absolute-12, #141414);
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .callUsTitle {
      text-align: left;
    }
    .fp-overflow {
      display: flex;
      padding: 88px 24px 0 24px;
      box-sizing: border-box;
      flex-direction: column;
      height: calc(var(--vh) * 100);
      width: 100vw;
      justify-content: center;
      align-items: flex-start;
    }

    .headMenuBg {
      width: 100%;
      height: 88px;
      position: sticky;
      top: 0px;
      background-color: #fff;
    }

    .callUsTitle {
      color: var(--Neutral-Absolute-13_black, #000);

      font-size: 28px;
      font-style: normal;
      font-weight: 900;
      line-height: 36px; /* 128.571% */
      text-align: left;
      // margin-top: 26px;
    }

    .callUsBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 24px;
      box-sizing: border-box;
      // overflow-y: scroll;

      .callUsItem {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 54px;

        .callUsItemSon{
          display: flex;
          align-items: center;
        }

        .callUsItemWay {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 144.444% */
          display: flex;
          align-items: center;

          svg {
            width: 32px;
            height: 32px;
            margin-right: 16px;
          }

          .callUsItemWayName{
            display: none;
          }
        }

        .callUsItemNum {
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 900;
          line-height: 32px; /* 125% */
          // margin-top: 16px;
        }

        .callUsItemTime {
          display: flex;
          // flex-wrap: nowrap;
          flex-direction: column;
          color: var(--Neutral-Absolute-8, #595959);

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 157.143% */
          margin: 12px 0 0 0;
          height: 44px;
          .callUsItemTimeData{
            display: flex;
          }

          .colon {
            display: block;
          }
        }

        .callUsItemTimeEmail {
          display: none;
        }

        .callUsItemBtn {
          display: flex;
          width: 144px;
          height: 40px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 2px solid #ff5010;
          color: var(--Neutral-Absolute-12, #141414);

          font-size: 16px;
          // font-style: normal;
          font-weight: 800;
          line-height: 24px; /* 144.444% */
          margin-top: 12px;
          cursor: pointer;
          a {
            color: var(--Neutral-Absolute-12, #141414);
          }
        }
      }

      .callUsItemLast{
        margin-bottom: 20px;
      }

      .callUsItemPad{
        margin-bottom: 0px;
      }
    }
  }
}
