.sectionNews{
  .fp-overflow{
    height: calc(var(--vh) * 100);
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .article{
    cursor: pointer;
  }

  .btn:hover{
    background-color: unset;
    // border-color: unset;
    border: 1px solid rgba($color: #000000, $alpha: 0);
    color: #FF5010;
  }

  .btn:active{
    background-color: unset;
    border: 1px solid rgba($color: #000000, $alpha: 0);
    color: #FF5010;
  }
  
  @media screen and (min-width: 1025px) {
    .headMenuBg{
      height: 120px;
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 96;
    }

    .title {
      color: #000;
       
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 68px; /* 115.385% */
    }
    
    .tip{
      color: var(--Neutral-Absolute-8, #595959);
      text-align: justify;
       
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 144.444% */
      margin: 24px;
    }

    .btn{
      color: #FF5010;
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 24px; /* 150% */
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        height: 16px;
        width: 16px;
        margin-left: 10px;
      }
    }

    .articleBox{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 64px;
      padding: 0 88px;
      width: 100%;
    }

    .article{
      width: calc((100vw - 368px)/3);
      margin: 0 32px;
      display: flex;
      flex-direction: column;

      .articleImg{
        width: 100%;

        img{
          width: 100%;
          // height: 268px;
          height: 30vh;
          object-fit: cover;
        }
      }

      .articleTitle{
        width: auto;
        height: 64px;
        color: var(--Neutral-Absolute-13_black, #000);
        font-family: Avenir;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 133.333% */
        overflow:hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        margin: 24px 0 12px 0;
      }

      .articleTime{
        width: auto;
        color: var(--Neutral-Absolute-8, #595959);
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }

  @media screen and (max-width: 768px) {
    .headMenuBg{
      height: 88px;
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 96;
    }

    .fp-overflow{
      height: calc(var(--vh) * 100);
      padding: 0 24px;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .title {
      color: #000;
      margin-top: 88px;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 128.571% */
    }
    
    .tip{
      color: var(--Neutral-Absolute-8, #595959);
      text-align: justify;
       
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      margin: 8px 0;
    }

    .btn{
      color: #FF5010;
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 24px; /* 150% */
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px !important;
      margin-bottom: 24px;

      img{
        height: 16px;
        width: 16px;
        margin-left: 10px;
      }
    }

    .articleBox{
      width: 100%;
      // flex-grow: 1;
      display: flex;
      flex-direction: column;
      // overflow-y: scroll;
      // height: calc(var(--vh) * 100 - 300px ); 
      // scrollbar-width: none;
      // -ms-overflow-style: none;
    }

    .article{
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 24px;
      margin-bottom: 30px;

      .articleImg{
        width: 100%;
        height: 239px;
        display: none;

        img{
          width: 100%;
          height: 239px;
          object-fit: cover;
        }
      }

      .articleTitle{
        width: 100%;
        color: #000;
         
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 144.444% */
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 4px;
      }

      .articleTime{
        color: var(--color-text-Primary, #1C2026);
        font-family: Avenir;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 157.143% */
        margin: 8px 0 0 0;
      }
    }

    .article:last-child{
      margin-bottom: 0px;
    }

    // .article:nth-child(3){
    //   display: none;
    // }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .headMenuBg{
      height: 88px;
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 96;
    }

    .title {
      color: #000;
       
      font-size: 52px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 128.571% */
    }
    
    .tip{
      color: var(--Neutral-Absolute-8, #595959);
      text-align: justify;
       
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      margin: 24px;
    }

    .btn{
      color: #FF5010;
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 24px; /* 150% */
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        height: 16px;
        width: 16px;
        margin-left: 10px;
      }
    }

    .articleBox{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 64px;
    }

    .article{
      width: 357px;
      margin: 0 32px;
      display: flex;
      flex-direction: column;

      .articleImg{
        width: 100%;
        height: 268px;

        img{
          width: 100%;
          height: 268px;
          object-fit: cover;
        }
      }

      .articleTitle{
        width: 357px;
        color: var(--Neutral-Absolute-13_black, #000);
         
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 133.333% */
        white-space:nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 24px 0 12px 0;
      }

      .articleTime{
        color: var(--Neutral-Absolute-8, #595959);
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
    
    .article:nth-child(3){
      display: none;
    }
  }
}