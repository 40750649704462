/* 在小屏幕设备上使用的样式 */
@media screen and (max-width: 768px) {
    .contactUs {
        padding-left: 24px;
        width: 100vw;
        height: calc(var(--vh) * 100);
        font-family: Avenir;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        .contactUs-tips{
            color: #000;
            font-size: 28px;
            font-weight: 600;
            line-height: 60px;
            margin-bottom: 32px;
        }
        .contactUs-form{
            .contactUs-form-submit{
                background: #fff;
                border-radius: 4px;
                font-weight: 500;
                width: 116px;
                height: 40px;
                font-size: 16px;
            }
            .disabled {
                border: 2px solid #F0F0F0 !important;
                background-color: #fff !important;
                color: #D9D9D9 !important;
            }
            .un-disabled { 
                color: #000 !important;
                border: 2px solid #FF5010;
                color: #000 !important;
                &:hover, &:active {
                    color: #fff !important;
                    background-color: #FF5010 !important;
                }
            }
        }
    }
    .contactUs-right {
        background: #000;
        padding: 142px 48px 64px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items:flex-start;
        width: 100vw;
        height: calc(var(--vh) * 100);
        .contactUs-right-content {
            .desc,
            .email {
                color: #FFF;
                font-size: 18px;
                font-weight: 400;
                line-height: 26px;
            }
            .email{
                margin-top: 8px;
                text-decoration: none;
                color: #FF5010;
            }
            .position {
                color: #FFF;
                font-size: 18px;
                font-weight: 400;
                line-height: 26px;
                margin-top: 48px;
                .position-desc {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: rgba(255, 255, 255, 0.45);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    margin-top: 8px;
                    a {
                        color: rgba(255, 255, 255, 0.45);
                    }
                }
                .filings{
                    display: block;
                    color: rgba(255, 255, 255, 0.45);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    margin-top: 24px;
                    cursor: pointer;
                }
            }
        }
        .contactUs-right-master {
            margin-top: 26vh;
            .master-item {
                &:hover, &:active {
                    .master-qrcode{
                        display: block;
                    }
                }
                .master-item-wrap{
                    display: flex;
                    justify-content: center;
                    .master-item-img {
                        width: 56px;
                        height: 56px;
                        border-radius: 50%;
                        margin-bottom: 24px;
                        cursor: pointer;
                    }
                    .master-item-right {
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 400;
                        margin-left: 16px;
                        .master-identity{
                            font-size: 14px;
                        }
                    }

                    .master-item-left {
                        position: relative;
                        .master-wechat {
                            position: absolute;
                            cursor: pointer;
                            top: 40px;
                            right: 0;
                            background-color: #05C160;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            padding: 2px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img {
                                width: 12px;
                                height: 9.7px;
                            }
                        }
                    }
                }
                .master-qrcode {
                    display: none;
                    position: absolute;
                    bottom: 154px;
                    width: 100%;

                    .master-qrcode-wrap{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background: #fff;
                        padding: 24px;
                        // width: 324px;
                        width: calc(100% - 48px);
                        .master-qrcode-icon{
                            width: 80px;
                            height: 80px;
                        }
                        .master-qrcode-desc{
                            margin-top: 16px;
                            width: 276px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px;
                            font-family: Avenir;
                            letter-spacing: 0em;
                        }
                    }
                }
            }
        }

    }

    .ant-form-item-row{
        .ant-form-item-label{
            padding: 0 !important;
        }
        input {
            padding: 8px 0 !important;
            &::placeholder {
                color: #EAEAEA;
                font-size: 24px;
                font-weight: 500;
                line-height: 28px !important;
            }
        }
    }

    .audit {
        display: none;
    }
}
   
    /* 在中等屏幕设备上使用的样式 */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .contactUs{
        display: flex;
        width: 100vw;
        height: calc(var(--vh) * 100);
        font-family: Avenir;
        .contactUs-left {
            width: 50vw;
            display: flex;
            padding:142px 48px;
            justify-content: center;
            .contactUs-left-wrap {
                // padding:142px 48px;
            }
            .contactUs-tips{
                color: #000;
                font-size: 28px;
                font-weight: 600;
                line-height: 60px;
                margin-bottom: 32px;
            }
            .contactUs-form{
                .contactUs-form-submit{
                    background: #fff;
                    border-radius: 4px;
                    font-weight: 500;
                    width: 116px;
                    height: 40px;
                }
                .disabled {
                    border: 2px solid #F0F0F0 !important;
                    background-color: #fff !important;
                    color: #D9D9D9 !important;
                }
                .un-disabled { 
                    color: #000 !important;
                    border: 2px solid #FF5010;
                    color: #000 !important;
                    &:hover, &:active {
                        color: #fff !important;
                        background-color: #FF5010 !important;
                    }
                }
            }
        }
        .contactUs-right {
            background: #000;
            width: 50vw;
            padding: 142px 48px 64px 48px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .contactUs-right-content {
                .desc,
                .email {
                    color: #FFF;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px;
                }
                .email{
                    margin-top: 8px;
                    text-decoration: none;
                    color: #FF5010;
                }
                .position {
                    color: #FFF;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px;
                    margin-top: 48px;
                    .position-desc {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        color: rgba(255, 255, 255, 0.45);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                        margin-top: 8px;
                        a {
                            color: rgba(255, 255, 255, 0.45);
                        }
                    }
                    .filings{
                        display: none;
                        color: rgba(255, 255, 255, 0.45);
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        margin-top: 24px;
                        cursor: pointer;
                    }
                }
            }
            .contactUs-right-master {
                .master-item {
                    position: relative;
                    &:hover, &:active {
                        .master-qrcode{
                            display: block;
                        }
                    }
                    .master-item-wrap{
                        display: flex;
                        cursor: pointer;
                        .master-item-img {
                            width: 56px;
                            height: 56px;
                            border-radius: 50%;
                            margin-bottom: 24px;
                        }
                        .master-item-right {
                            color: #FFF;
                            font-size: 18px;
                            font-weight: 400;
                            margin-left: 16px;
                            .master-identity{
                                font-size: 14px;
                            }
                        }
    
                        .master-item-left {
                            position: relative;
                            .master-wechat {
                                position: absolute;
                                cursor: pointer;
                                top: 40px;
                                right: 0;
                                background-color: #05C160;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                padding: 2px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img {
                                    width: 12px;
                                    height: 9.7px;
                                }
                            }
                        }
                    }
                    .master-qrcode {
                        display: none;
                        position: absolute;
                        bottom: 100px;
                        width: 100%;

                        .master-qrcode-wrap{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            background: #fff;
                            padding: 24px;
                            .master-qrcode-icon{
                                width: 80px;
                                height: 80px;
                            }
                            .master-qrcode-desc{
                                margin-top: 16px;
                                width: 276px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px;
                                font-family: Avenir;
                                letter-spacing: 0em;
                            }
                        }
                    }
                }
            }

        }
    }
  }

   /* 在大屏幕设备上使用的样式 */ 
  @media screen and (min-width: 1025px) {
    .contactUs{
        display: flex;
        width: 100vw;
        height: calc(var(--vh) * 100);
        font-family: Avenir;
        .contactUs-left {
            width: calc(100vw - 420px);
            display: flex;
            align-items: center;
            justify-content: center;
            .contactUs-left-wrap {
                width: 450px;
                // padding-left: 48px;
            }
            .contactUs-tips{
                color: #000;
                font-size: 60px;
                font-weight: 600;
                line-height: 60px;
                margin-bottom: 32px;
            }
            .contactUs-form{
                .contactUs-form-submit{
                    background: #fff;
                    border-radius: 4px;
                    font-weight: 500;
                    width: 116px;
                    height: 40px;
                    font-size: 18px;
                }
                .disabled {
                    border: 2px solid #F0F0F0 !important;
                    background-color: #fff !important;
                    color: #D9D9D9 !important;
                }
                .un-disabled { 
                    color: #000 !important;
                    border: 2px solid #FF5010;
                    color: #000 !important;
                    &:hover, &:active {
                        color: #fff !important;
                        background-color: #FF5010 !important;
                    }
                }
            }
        }
        .contactUs-right {
            background: #000;
            width: 420px;
            padding: 142px 48px 64px 48px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items:flex-start;
            .contactUs-right-content {
                .desc,
                .email {
                    color: #FFF;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px;
                }
                .email{
                    margin-top: 8px;
                    text-decoration: none;
                    color: #FF5010;
                }
                .position {
                    color: #FFF;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px;
                    margin-top: 48px;
                    .position-desc {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        color: rgba(255, 255, 255, 0.45);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                        margin-top: 8px;
                        a {
                            color: rgba(255, 255, 255, 0.45);
                            &:hover, &:active{
                                color: #fff;
                            }
                        }
                    }
                    .filings{
                        display: none;
                        color: rgba(255, 255, 255, 0.45);
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        margin-top: 24px;
                        cursor: pointer;
                    }
                }
            }
            .contactUs-right-master {
                position: relative;
                .master-item {
                    &:hover, &:active {
                        .master-qrcode{
                            display: block;
                        }
                    }
                    .master-item-wrap{
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        .master-item-img {
                            width: 56px;
                            height: 56px;
                            border-radius: 50%;
                            margin-bottom: 24px;
                        }
                        .master-item-right {
                            color: #FFF;
                            font-size: 18px;
                            font-weight: 400;
                            margin-left: 16px;
                            .master-identity{
                                font-size: 14px;
                            }
                        }
    
                        .master-item-left {
                            position: relative;
                            .master-wechat {
                                position: absolute;
                                cursor: pointer;
                                top: 40px;
                                right: 0;
                                background-color: #05C160;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                padding: 2px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img {
                                    width: 12px;
                                    height: 9.7px;
                                }
                            }
                        }
                    }
                    .master-qrcode {
                        display: none;
                        position: absolute;
                        bottom: 100px;
                        .master-qrcode-wrap{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            background: #fff;
                            padding: 24px;
                            width: 324px;
                            .master-qrcode-icon{
                                width: 80px;
                                height: 80px;
                            }
                            .master-qrcode-desc{
                                margin-top: 16px;
                                width: 276px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px;
                                font-family: Avenir;
                                letter-spacing: 0em;
                            }
                        }
                    }
                }
            }
    
        }

        .audit {
            position: absolute;
            left: 48px;
            bottom: 64px
        }
    }

  }

  .enStyle {
    font-family: Avenir !important;
  }
  .enStyle .ant-form-item-label {
    font-family: Avenir !important;
  }
  .contactUs-form{
    .ant-form-item-explain-error{
        display: none;
        // color: #FF5010 !important;
        // font-weight: 500;
        // z-index: 2222;
    }
    .form-item-label-focus {
        label {
            // color: #FF5010 !important;
            // color: #BFBFBF !important;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
        }
    }
    .ant-form-item-row{
        label {
            color: #000;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            &::before{
                display: none !important;
            }
        }
        input {
            padding: 4px 0;
            border-width: 0;
            border-bottom-width: 1px;
            border-radius: 0;
            border-color: #D9D9D9 !important;
            font-size: 24px;
            font-weight: 600;
            &:focus {
                border-width: 0;
                border-bottom-width: 1px;
                border-inline-end-width: 0 !important;
                box-shadow: none !important;
                border-color: #D9D9D9;
            }
            &::placeholder {
                color: #EAEAEA;
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
            }
            
        }
    }
    .ant-form-item-has-error{
        label {
            color: #FF5010;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            &::before{
                display: none !important;
            }
        }
        .input{
            border-bottom-width: 0px;
        }
    }
    .ant-form-item-has-success{
        label {
            color: #BFBFBF;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            &::before{
                display: none !important;
            }
        }
    }
    .form-item-phone{
        display: flex;
        .form-item-phoneArea {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            // border-bottom: 1px solid #d9d9d9;
            &:hover, &:active {
                .phoneArea-selecter{
                    display: block;
                }
            }
            .form-item-phoneArea-lable{
                color: rgba(0, 0, 0, 0.88);
                font-family: Avenir;
                font-size: 24px;
                font-style: normal;
                font-weight: 800;
                line-height: 32px;
            }
            .phoneArea-select-icon{
                width: 16px;
                height: 16px;
                margin-left: 4px;
            }
            .phoneArea-selecter {
                position: absolute;
                display: none;
                font-size: 14px;
                z-index: 100;
                width: 72px;
                padding: 4px;
                background-color: #fff;
                border-radius: 2px;
                box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
                .phoneArea-selecter-item {
                    display: flex;
                    padding: 5px 12px;
                    align-items: center;
                    justify-content: left;
                    &:hover, &:active {
                        background-color: rgba(0, 0, 0, 0.04);
                        border-radius: 4px;
                    }
                }
            }
        }
  
    }
  }

  a {
    text-decoration: none;
  }