.headMenuBg{
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
.paging-wrap{
  display: flex;
  justify-content: center;
  width: 100%;

  .pageNum{
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 12px;
    color: var(--color-text-black, #000);
    text-align: center;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    cursor: pointer;

    .underLine{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 16px;
      height: 1px;
      background: var(--color-text-black, #000);
    }
  }
}

.layoutTabs{
  div{
    cursor: pointer
  }
}
@media screen and (min-width: 1200px) {
  .headMenuBg{
    height: 120px;
  }

  .informationLayout {
    padding: 144px 0 72px 0;
    width: 1200px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;
  }

  .breadcrumb{
    width: 100%;
    margin: 24px 0 48px 0;
    display: flex;
    justify-content: flex-end;
    color: var(--color-alpha-b40, rgba(0, 0, 0, 0.40));
    /* 400/12 */
     
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    cursor: pointer;

    span:hover{
      color: #FF5010;
    }
  }

  .layoutTitle{
    color: var(--color-text-Primary, #1C2026);
     
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 150% */
  }

  .layoutTabs{
    margin: 48px 0;
    display: flex;

    div{
      color: var(--color-alpha-b40, rgba(0, 0, 0, 0.40));
       
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      margin-right: 64px;
      cursor: pointer;
    }

    .onSelect{
      color: #FF5010;
    }
  }

  .informationItem{
    display: flex;
    margin-bottom: 48px;

    .informationImg{
      height: 540px;
      width: 720px;
      cursor: pointer;

      img{
        height: 540px;
        width: 720px;
        object-fit: cover;
      }
    }

    .itemInfoBox{
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      // width: 424px;
      flex-grow: 1;
      padding-left: 56px;

      .itemTitle{
        color: var(--color-text-Primary, #1C2026);
         
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 133.333% */
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;

      }

      .itemTime{
        color: var(--color-text-Primary, #1C2026);
        font-family: Avenir;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        margin: 8px 0 32px 0;
        cursor: pointer;
      }

      .itemDesc{
        color: var(--color-text-Primary, #1C2026);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        cursor: pointer;

        .itemDescOmit{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        span{
          color: #FF5010;
          cursor: pointer;
        //   display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .headMenuBg{
    height: 88px;
  }

  .informationLayout {
    padding: 112px 24px 24px 24px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .breadcrumb{
    display: none;
  }
  
  .layoutTitle{
    color: var(--color-text-Primary, #1C2026);
     
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 128.571% */
  }

  ::-webkit-scrollbar {
    display: none;
  }
  .layoutTabs{
    margin: 24px 0;
    display: flex;
    overflow-y: scroll;
    scrollbar-width: none;
    scrollbar-color: rgba(0, 0, 0, 0);
    scrollbar-highlight-color: rgba(0, 0, 0, 0);
    scrollbar-base-color: rgba(0, 0, 0, 0);
    scrollbar-arrow-color: rgba(0, 0, 0, 0);
    -ms-overflow-style: none;

    div{
      color: var(--color-alpha-b40, rgba(0, 0, 0, 0.40));
       
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      margin-right: 32px;
      white-space: nowrap;
    }

    .onSelect{
      color: #FF5010;
    }
  }
  
  .informationItem{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 54px;

    .informationImg{
      width: 100%;
      // display: none;

      img{
        width: 100%;
        object-fit: cover;
      }
    }

    .itemInfoBox{
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      // width: 424px;
      flex-grow: 1;
    //   border-bottom: 1px solid #D9D9D9;

      .itemTitle{
        color: var(--color-text-Primary, #1C2026);
        /* 400/24 */
         
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 133.333% */
        margin-top: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }

      .itemTime{
        color: var(--color-text-Primary, #1C2026);
        font-family: Avenir;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        margin: 8px 0 8px 0;
      }

      .itemDesc{
        display: none;
        color: var(--color-text-Primary, #1C2026);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        // display: none;
        .itemDescOmit{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        span{
          color: #FF5010;
          cursor: pointer;
          display: flex;
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .headMenuBg{
    height: 88px;
  }

  .informationLayout {
    padding: 112px 24px 24px 24px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .breadcrumb{
    width: 100%;
    margin: 24px 0 48px 0;
    display: flex;
    justify-content: flex-end;
    color: var(--color-alpha-b40, rgba(0, 0, 0, 0.40));
    /* 400/12 */
     
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }
  
  .layoutTitle{
    color: var(--color-text-Primary, #1C2026);
     
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 128.571% */
  }

  .layoutTabs{
    margin: 24px 0;
    display: flex;

    div{
      color: var(--color-alpha-b40, rgba(0, 0, 0, 0.40));
       
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      margin-right: 32px;
    }

    .onSelect{
      color: #FF5010;
    }
  }
  
  .informationItem{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 54px;

    .informationImg{
      width: 100%;

      img{
        width: 100%;
        object-fit: cover;
      }
    }

    .itemInfoBox{
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      // width: 424px;
      flex-grow: 1;

      .itemTitle{
        color: var(--color-text-Primary, #1C2026);
        /* 400/24 */
         
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 133.333% */
        margin-top: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }

      .itemTime{
        color: var(--color-text-Primary, #1C2026);
        font-family: Avenir;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        margin: 8px 0 24px 0;
      }

      .itemDesc{
        color: var(--color-text-Primary, #1C2026);
         
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        .itemDescOmit{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        span{
          color: #FF5010;
          cursor: pointer;
        //   display: none;
        }
      }
    }
  }
}