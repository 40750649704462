/* 在小屏幕设备上使用的样式 */
@media screen and (max-width: 768px) {
    .office-city{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #000;
        width: 100vw;
        height: calc(var(--vh) * 100);
        color: #fff;
        padding: 24px;
        .office-city-tips {
            font-size: 28px;
            font-weight: 600;
            line-height: 36px;
        }
        .office-city-content{
            margin-top: 48px;
            .office-city-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 32px 20px 0;
                border-bottom:  1px #595959 solid;
                &:last-child {
                    border-bottom: none;
                }
                // &:hover {
                //     .office-city-btn {
                //         display: block;
                //     }
                // }
                .office-city-name {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px;
                }
                .office-city-btn {
                    display: none;
                    color: #FF5010;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 26px;
                }
            }
        }
    }
 
}
   
    /* 在中等屏幕设备上使用的样式 */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .office-city{
        .office-city-tips {
            color: #000;
            font-size: 48px;
            font-weight: 600;
            line-height: 60px;
        }
        .office-city-content{
            margin-top: 24px;
            .office-city-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 32px 20px 0;
                border-bottom:  1px #F0F0F0 solid;
                cursor: pointer;
                &:last-child {
                    border-bottom: none;
                }
                &:hover {
                    .office-city-btn {
                        display: block;
                    }
                }
                .office-city-name {
                    color: #000;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px;
                }
                .office-city-btn {
                    display: none;
                    color: #FF5010;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 26px;
                }
            }
        }
    }
  }

   /* 在大屏幕设备上使用的样式 */ 
  @media screen and (min-width: 1025px) {
    .office-city{
        .office-city-tips {
            color: #000;
            font-size: 52px;
            font-weight: 600;
            line-height: 60px;
        }
        .office-city-content{
            margin-top: 24px;
            .office-city-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 32px 20px 0;
                border-bottom:  1px #F0F0F0 solid;
                cursor: pointer;
                &:last-child {
                    border-bottom: none;
                }
                &:hover {
                    .office-city-btn {
                        display: block;
                    }
                }
                .office-city-name {
                    color: #000;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px;
                }
                .office-city-btn {
                    display: none;
                    color: #FF5010;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 26px;
                }
            }
        }
    }
  }