.sectionJoinUs{
  .fp.fp-overflow{
    height: calc(var(--vh) * 100);
    width: 100vw;
  }

  @media screen and (max-width: 768px) {
    .sectionJoinUs {
      display: flex;
      align-items: center;
      justify-content: center
    }

    .sectionJoinUs-content{
      height: calc(var(--vh) * 100);
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
      box-sizing: border-box;
      padding: 56px 24px;
      z-index: 99;

      .sectionJoinUs-title{
        width: 272px;
        color: #FFF;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 113.333% */
        text-align: left;
      }

      .sectionJoinUs-tip{
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 144.444% */
        margin: 12px 0 32px 0;
      }

      .sectionJoinUs-btn{
        cursor: pointer;
        padding: 8px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 2px solid #FF5010;
        color: var(--Neutral-Absolute-1_white, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 144.444% */
      }
    }
  }
   
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .sectionJoinUs {
      display: flex;
      align-items: center;
      justify-content: center
    }

    .sectionJoinUs-content{
      height: calc(var(--vh) * 100);
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 99;

      .sectionJoinUs-title{
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px; /* 113.333% */
      }

      .sectionJoinUs-tip{
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
        margin: 24px 0 32px 0;
      }

      .sectionJoinUs-btn{
        cursor: pointer;
        padding: 12px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 2px solid #FF5010;
        color: var(--Neutral-Absolute-1_white, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
      }
    }
  }
   
  @media screen and (min-width: 1025px) {
    .sectionJoinUs {
      display: flex;
      align-items: center;
      justify-content: center
    }

    .sectionJoinUs-content{
      height: calc(var(--vh) * 100);
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 99;

      .sectionJoinUs-title{
        color: #FFF;
        text-align: center;
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: 68px; /* 113.333% */
      }

      .sectionJoinUs-tip{
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
        margin: 24px 0 32px 0;
      }

      .sectionJoinUs-btn{
        cursor: pointer;
        padding: 12px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 2px solid #FF5010;
        color: var(--Neutral-Absolute-1_white, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
      }

      .sectionJoinUs-btn:hover{
        background-color: #FF5010;
      }
    }
  }
  
}