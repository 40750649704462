@media screen and (max-width: 768px) {
    .headMenuBg{
        height: 88px;
      }
    /* 在小屏幕设备上使用的样式 */
    .news-detail{
        padding: 42px 24px 24px 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .news-detail-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        .news-detail-nav{
            width: 100%;
            color: #999999;
             
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            div{
                cursor: pointer;
            }
        }
        .news-detail-title{
            width: 100%;
            font-size: 24px;
            font-weight: 800;
            line-height: 32px;
            margin-bottom: 24px;
            margin-top: 48px;
        }
        .news-detail-date {
            color: #999;
            width: 100%;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 12px;
        }
        .news-detail-content {
            width: 100%;
            p{
                 
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                text-align: left !important;
                display: flex;
                flex-direction: column;
                img {
                    margin-top: 32px;
                    width: 100% !important;
                    height: 329px !important;
                    object-fit: cover;
                }
                a{
                    text-decoration: none !important;
                }
            }
        }
        .news-detail-footer-divider{
            margin: 48px  0;
        }
        .news-detail-footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #000;
            font-size: 12px;
            font-weight: 200;
            line-height: 22px;
            .news-detail-footer-pre,
            .news-detail-footer-next{
                display: flex;
                align-items: center;
                cursor: pointer;

                .footer-pre-title,
                .footer-next-title{
                    width: 120px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                &:hover,&:active {
                    color: #FF5010;
                }
                .pre-arrow-left{
                    height: 12px;
                    width: 12px;
                    margin-right: 8px;
                }
                .next-arrow-right{
                    height: 12px;
                    width: 12px;
                    margin-left: 8px;
                    transform: rotate(180deg);
                }
        }
        }
    }
  }
   
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    /* 在中等屏幕设备上使用的样式 */
    .news-detail{
        padding: 112px 24px 24px 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .news-detail-wrap{
            max-width: 1200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 24px;
        }
        .news-detail-nav{
            width: 100%;
            color: #999999;
             
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            div{
                cursor: pointer;
            }
        }
        .news-detail-title{
            width: 100%;
            font-size: 36px;
            font-weight: 800;
            line-height: 32px;
            margin-bottom: 24px;
            margin-top: 48px;
        }
        .news-detail-date {
            width: 100%;
            font-family: Avenir;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 48px;
        }
        .news-detail-content {
            width: 100%;
            p{
                 
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                text-align: left !important;
                display: flex;
                flex-direction: column;
                img {
                    margin-top: 56px;
                    height: 576px !important;
                    width: 100% !important;
                    object-fit: cover;
                }
                a{
                    text-decoration: none !important;
                }
            }
        }
        .news-detail-footer-divider{
            margin: 48px  0;
        }
        .news-detail-footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #1C2026;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            .news-detail-footer-pre,
            .news-detail-footer-next{
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover,&:active {
                    color: #FF5010;
                }
                .footer-pre-title,
                .footer-next-title{
                    width: 200px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .pre-arrow-left{
                    height: 16px;
                    width: 16px;
                    margin-right: 8px;
                }
                .next-arrow-right{
                    height: 16px;
                    width: 16px;
                    margin-left: 8px;
                    transform: rotate(180deg);
                }
        }
        }
    }
  }
   
  @media screen and (min-width: 1025px) {
    /* 在大屏幕设备上使用的样式 */
    .news-detail{
        padding: 112px 24px 24px 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        .news-detail-wrap{
            max-width: 1200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 24px;
        }
        .news-detail-nav{
            width: 100%;
            color: #999999;
             
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            div{
                cursor: pointer;
            }
            .news-detail-link:hover {
                color: #FF5010;
            }
        }
        .news-detail-title{
            width: 100%;
            font-size: 36px;
            font-weight: 400;
            line-height: 32px;
            margin-bottom: 24px;
            margin-top: 48px;
        }
        .news-detail-date {
            width: 100%;
            font-family: Avenir;
            font-size: 12px;
            font-weight: 800;
            line-height: 20px;
            margin-bottom: 48px;
        }
        .news-detail-content {
            width: 100%;
            p{
                 
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                text-align: left !important;
                display: flex;
                flex-direction: column;
                margin-left: 0 !important;
                img {
                    margin-top: 56px;
                    height: 900px !important;
                    width: 100% !important;
                    object-fit: cover;
                }
                a{
                    text-decoration: none !important;
                }
            }
        }
        .news-detail-footer-divider{
            margin: 48px  0;
        }
        .news-detail-footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #1C2026;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            .news-detail-footer-pre,
            .news-detail-footer-next{
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover,&:active {
                    color: #FF5010;
                }
                .footer-pre-title,
                .footer-next-title{
                    width: 300px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .pre-arrow-left{
                    height: 16px;
                    width: 16px;
                    margin-right: 8px;
                }
                .next-arrow-right{
                    height: 16px;
                    width: 16px;
                    margin-left: 8px;
                    transform: rotate(180deg);
                }
        }
        }
    }
  }