@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
.en-all-family {
  font-family: avenir;
}
.zh-all-family {
  font-family: "PingFang SC";
}
#fullpage>div{
  height: calc(var(--vh) * 100 ) !important;
}
.g-search-form-wrap {
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  .col {
    flex: 0 0 auto;
    padding-left: 3.33%;
    width: 33.33%;

    &:nth-child(3n-2) {
      padding-left: 0;
    }
  }

  .col2 {
    width: 66.66%;
  }

  .col3 {
    width: 100%;
  }

  .ant-form-item {
    margin-left: 0;
    margin-right: 0;
  }
}

.ta-r {
  text-align: right;
}

.ta-l {
  text-align: left;
}

.mr-8{
  margin-right: 8px;
}

.ml-8{
  margin-left: 8px;
}

.mt-4{
  margin-top: 4px;
}

.mt-8{
  margin-top: 8px;
}

.mt-16{
  margin-top: 16px;
}

.mt-24{
  margin-top: 24px;
}

.mb-8{
  margin-bottom: 8px;
}

.mb-16{
  margin-bottom: 16px;
}

.mb-24{
  margin-bottom: 24px;
}

.c-r{
  color: red;
}

.w-100{
  width: 100%;
}

.fp-watermark{
  display: none;
}